// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage, getDownloadURL, ref } from 'firebase/storage'

const firebaseConfig = {
  apiKey: "AIzaSyAizbvxE67i0OsEIpBCYiZmLanMOPZvt4o",
  authDomain: "rolf-780ff.firebaseapp.com",
  databaseURL: "https://rolf-780ff-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "rolf-780ff",
  storageBucket: "rolf-780ff.appspot.com",
  messagingSenderId: "228588044345",
  appId: "1:228588044345:web:2aeb79a37f88fea390cde8",
  measurementId: "G-3X7T48GZ9T"
};

initializeApp(firebaseConfig);

const storage = getStorage()

const getBongoMovie = async () => {
  return getDownloadURL(ref(storage, 'videos/bongo.mp4'))
      .then((url) => url)
      .catch((error) => {
        console.error(error)
      });
}

export { getBongoMovie }