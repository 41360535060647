import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components"
import { motion } from 'framer-motion'
import Layout from '../shared/Full'
import Pattern from '../images/Pattern-01.png'
import { getBongoMovie } from "../firebase"
import { Helmet } from 'react-helmet'
import ogImg from '../images/start.png'
import { Link } from 'gatsby'

const Refresh = () => {
  return(
    <motion.div
      className="refresh"
      initial="rest"
      whileHover="hover"
      whileTap="pressed"
    >
    </motion.div>
  )
}

const DraggableVideo = () => {
  const [videoUrl, setVideoUrl] = useState()
  // Run on mount
  useEffect(() => {
    const getAndLoadHttpUrl = async () => {
      getBongoMovie().then(url => setVideoUrl(url)).catch(err => console.log({err}))
    }
    getAndLoadHttpUrl()
    return () => setVideoUrl()
  }, [])

  const constraintsRef = useRef(null);
  return (
    <>
    <motion.div className="drag-area"  ref={constraintsRef}/>
    <motion.div drag dragConstraints={constraintsRef}>
      {videoUrl && (
      <video className="iframeClass" autoPlay loop controls> 
          <source id="video" src={videoUrl} type="video/mp4" autoPlay/>
      </video>
      )}
    </motion.div>
    </>
  )
}

const HeroMessage = ({topLine, bottomLine}) => {

  const ActionButton = styled.button`
    cursor: pointer;
    width: 250px;
    height: 60px;
    background: transparent;
    border: 2px solid #FDF9F0;
    border-radius: 10px;
    color: #FDF9F0;
    margin: 0 auto; 
    line-height: 0px;
    text-transform: uppercase;
    letter-spacing: 5px;
    font-size: 16px;

    & a {
      color: #FDF9F0;
      text-decoration: none;
    }
  `;

  return(
    <>
    <div className="big-boy">
      <h1>{topLine}<br/>{bottomLine}</h1>
      <ActionButton><Link to="https://biografi.rolfborjlind.com/">Biografin 📕</Link></ActionButton>
    </div>
    </>
  )
}

const Video = ({ videoSrc, ...props }) => {
  const Frame = styled.div`
    width: 100vw;
    height: 100vh;
    z-index: 99;
    background: #282828;
    position: relative;
    background: url(${Pattern});
    background-position: center center;
    background-size: cover;
    background-color: #282828;

    & .center-this {
      position: absolute;
      top: 10px;
      left: 200px;
    }
  `;

  const Overlay = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0px;
    left: 0px;
    background: #282828;
    display: flex; 
    flex-direction: row;
    flex-wrap: nowrap; 
    justify-content: center;
    align-items: center;
    align-content: center;
  `;

  const iframeClass = {
    width: '100vw',
    height: '100vh'
  }

  

  return(
    <div>
    
    <Frame>
    <HeroMessage className="center-this" topLine="bit den hand" bottomLine="som föder dig" />
    <Refresh />
    <div className="draggable-container">
      <DraggableVideo />
    </div>
      
    </Frame>
    </div>
  )
}

const StartPage = () => {
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }
  
  return (
    <Layout>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Bit den hand som föder dig - Rolf Börjlind</title>
        <meta name="description" content="En digital självbiografi som skildrar Rolf Börjlinds liv och verk." />
        <meta property="og:image" content={ogImg} />
    </Helmet>
      <Video videoSrc="https://www.youtube.com/embed/uXGE0vuuaDo" />
    </Layout>
          
  )
}

export default StartPage